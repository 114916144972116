// 公共api
import axios from '../request/request.js'
//
export default {
  //评论列表
  commentList(data) {
    return axios({
      url: "/admin/activity/activity_flow_comment",
      method: "get",
      data,
    });
  },
  // 发布评论
  commentAdd(data) {
    return axios({
      url: "/admin/activity/activity_flow_comment",
      method: "post",
      data,
    });
  },
  // 修改评论
  commentEdit(data, id) {
    return axios({
      url: "/admin/activity/activity_flow_comment/" + id,
      method: "put",
      data,
    });
  },
  // 删除评论
  commentDel(id) {
    return axios({
      url: "/admin/activity/activity_flow_comment/" + id,
      method: "delete",
    });
  },
  //回复列表
  replyList(data) {
    return axios({
      url: "/admin/activity/activity_flow_reply",
      method: "get",
      data,
    });
  },

  //流量圈列表
  flowList(data) {
    return axios({
      url: "/admin/activity/activity_flow",
      method: "get",
      data,
    });
  },
  // 详情
  flowInfo(id) {
    return axios({
      url: "/admin/activity/activity_flow/" + id,
      method: "get",
    });
  },
  // 发布
  flowAdd(data) {
    return axios({
      url: "/admin/activity/activity_flow",
      method: "post",
      data,
    });
  },
  // 修改
  flowEdit(data, id) {
    return axios({
      url: "/admin/activity/activity_flow/" + id,
      method: "put",
      data,
    });
  },
  // 删除
  flowDel(id) {
    return axios({
      url: "/admin/activity/activity_flow/" + id,
      method: "delete",
    });
  },
}
