// 公共api
import axios from '../request/request.js'
//
export default {
    //文章分类列表
    categoryList(data) {
        return axios({
            url: "/admin/article/category",
            method: "get",
            data,
        });
    },
    // 添加文章分类
    categoryAdd(data) {
        return axios({
            url: "/admin/article/category",
            method: "post",
            data,
        });
    },
    // 修改文章分类
    categoryEdit(data, id) {
        return axios({
            url: "/admin/article/category/" + id,
            method: "put",
            data,
        });
    },
    // 删除文章分类
    categoryDel(id) {
        return axios({
            url: "/admin/article/category/" + id,
            method: "delete",
        });
    },
    // 修改分类状态
    categoryStatus(data) {
        return axios({
            url: "/admin/article/category/changeStatus",
            method: "post",
            data,
        });
    },


    //标签列表
    labelList(data) {
        return axios({
            url: "/admin/article/label",
            method: "get",
            data,
        });
    },
    // 添加标签
    labelAdd(data) {
        return axios({
            url: "/admin/article/label",
            method: "post",
            data,
        });
    },
    // 修改标签
    labelEdit(data, id) {
        return axios({
            url: "/admin/article/label/" + id,
            method: "put",
            data,
        });
    },
    // 删除标签
    labelDel(id) {
        return axios({
            url: "/admin/article/label/" + id,
            method: "delete",
        });
    },
    // 修改标签状态
    labelStatus(data) {
        return axios({
            url: "/admin/article/label/changeStatus",
            method: "post",
            data,
        });
    },


    //文章列表
    articleList(data) {
        return axios({
            url: "/admin/article/article",
            method: "get",
            data,
        });
    },
    // 文章详情
    articleInfo(id) {
        return axios({
            url: "/admin/article/article/" + id,
            method: "get",
        });
    },
    // 添加文章
    articleAdd(data) {
        return axios({
            url: "/admin/article/article",
            method: "post",
            data,
        });
    },
    // 修改文章
    articleEdit(data, id) {
        return axios({
            url: "/admin/article/article/" + id,
            method: "put",
            data,
        });
    },
    // 删除文章
    articleDel(id) {
        return axios({
            url: "/admin/article/article/" + id,
            method: "delete",
        });
    },
    // 文章选项
    articleParams(data) {
        return axios({
            url: "/admin/article/article/params",
            method: "get",
            data
        });
    },
    // 修改文章状态
    articleStatus(data) {
        return axios({
            url: "/admin/article/article/changeStatus",
            method: "post",
            data,
        });
    },
    // 获取协议
    getMiniConfigs(data) {
        return axios({
            url: "/admin/configs/get_mini_configs",
            method: "get",
            data,
        });
    },
    // 修改协议
    saveMiniConfigs(data) {
        return axios({
            url: "/admin/configs/save_mini_configs",
            method: "post",
            data,
        });
    },
}
