// 数字市场
import axios from '../request/request.js'

export default {
  // 数字市场=模特管理-参数
  marketModelParams(data) {
    return axios({
      url: "/admin/market/model/params",
      method: "get",
      data,
    });
  },
  // 数字市场=模特管理-列表
  marketModel(data) {
    return axios({
      url: "/admin/market/model",
      method: "get",
      data,
    });
  },
  // 数字市场=模特管理-删除
  delMarketModel(data, id) {
    return axios({
      url: "/admin/market/model/" + id,
      method: "delete",
      data,
    });
  },
  // 数字市场=模特管理-状态
  marketModelStatus(data) {
    return axios({
      url: "/admin/market/model/status",
      method: "post",
      data,
    });
  },
  // 数字市场=模特管理-添加
  postMarketModel(data) {
    return axios({
      url: "/admin/market/model",
      method: "post",
      data,
    });
  },
  // 数字市场=模特管理-详情
  getMarketModel(data, id) {
    return axios({
      url: "/admin/market/model/" + id,
      method: "get",
      data,
    });
  },
  // 数字市场=模特管理-编辑
  putMarketModel(data, id) {
    return axios({
      url: "/admin/market/model/" + id,
      method: "put",
      data,
    });
  },

  // 数字市场=分类-列表
  marketCategory(data) {
    return axios({
      url: "/admin/market_category",
      method: "get",
      data,
    });
  },
  // 数字市场=分类-添加
  postMarketCategory(data) {
    return axios({
      url: "/admin/market_category",
      method: "post",
      data,
    });
  },
  // 数字市场=分类-编辑
  putMarketCategory(data, id) {
    return axios({
      url: "/admin/market_category/" + id,
      method: "put",
      data,
    });
  },
  // 数字市场=分类-删除
  delMarketCategory(id) {
    return axios({
      url: "/admin/market_category/" + id,
      method: "delete",
    });
  },
  // 数字市场=分类-修改状态
  changeMarketCategory(data) {
    return axios({
      url: "/admin/market_category/change_status",
      method: "post",
      data
    });
  },

  // 数字市场=声音分类-列表
  audioCategory(data) {
    return axios({
      url: "/admin/tts_type",
      method: "get",
      data,
    });
  },
  // 数字市场=声音分类-添加
  postAudioCategory(data) {
    return axios({
      url: "/admin/tts_type",
      method: "post",
      data,
    });
  },
  // 数字市场=声音分类-编辑
  putAudioCategory(data, id) {
    return axios({
      url: "/admin/tts_type/" + id,
      method: "put",
      data,
    });
  },
  // 数字市场=声音分类-删除
  delAudioCategory(id) {
    return axios({
      url: "/admin/tts_type/" + id,
      method: "delete",
    });
  },
  // 数字市场=声音分类-修改状态
  changeAudioCategory(data) {
    return axios({
      url: "/admin/tts_type/status",
      method: "post",
      data
    });
  },

  // 数字市场=名片-参数
  marketCardParams(data) {
    return axios({
      url: "/admin/market/card/params",
      method: "get",
      data,
    });
  },
  // 数字市场=名片-列表
  marketCard(data) {
    return axios({
      url: "/admin/market/card",
      method: "get",
      data,
    });
  },
  // 数字市场=名片-删除
  delMarketCard(data, id) {
    return axios({
      url: "/admin/market/card/" + id,
      method: "delete",
      data,
    });
  },
  // 数字市场=名片-状态
  marketCardStatus(data) {
    return axios({
      url: "/admin/market/card/status",
      method: "post",
      data,
    });
  },

  // 数字市场=模板-参数
  marketTemplateParams(data) {
    return axios({
      url: "/admin/market/template/params",
      method: "get",
      data,
    });
  },
  // 数字市场=模板-列表
  marketTemplate(data) {
    return axios({
      url: "/admin/market/template",
      method: "get",
      data,
    });
  },
  // 数字市场=模板-删除
  delMarketTemplate(data, id) {
    return axios({
      url: "/admin/market/template/" + id,
      method: "delete",
      data,
    });
  },
  // 数字市场=模板-状态
  marketTemplateStatus(data) {
    return axios({
      url: "/admin/market/template/status",
      method: "post",
      data,
    });
  },

  // 数字市场=声音-归属分类
  marketAudioCategory(data) {
    return axios({
      url: "/admin/tts_type/select",
      method: "get",
      data,
    });
  },
  // 数字市场=声音-归属专辑
  marketAudioAlbum(data) {
    return axios({
      url: "/admin/market_tts/pull_down_list",
      method: "get",
      data,
    });
  },
  // 数字市场=声音-列表
  marketAudio(data) {
    return axios({
      url: "/admin/market_tts",
      method: "get",
      data,
    });
  },
  // 数字市场=声音-详情
  marketAudioInfo(id) {
    return axios({
      url: "/admin/market_tts/" + id,
      method: "get",
    });
  },
  // 数字市场=声音-删除
  delMarketAudio(id) {
    return axios({
      url: "/admin/market_tts/" + id,
      method: "delete",
    });
  },
  // 数字市场=声音-添加
  postMarketAudio(data) {
    return axios({
      url: "/admin/market_tts",
      method: "post",
      data
    });
  },
  // 数字市场=声音-编辑
  putMarketAudio(data, id) {
    return axios({
      url: "/admin/market_tts/" + id,
      method: "put",
      data
    });
  },
  // 数字市场=声音-状态
  marketAudioStatus(data) {
    return axios({
      url: "/admin/market_tts/status",
      method: "post",
      data,
    });
  },
  // 数字市场=直播模板-分类
  liveCategories(data) {
    return axios({
      url: "/admin/live/member_live/categories",
      method: "get",
      data,
    });
  },
  // 数字市场=直播模板-列表
  liveTemplates(data) {
    return axios({
      url: "/admin/live/member_live/templates",
      method: "get",
      data,
    });
  },
  // 数字市场=直播模板-状态
  liveShelf(data) {
    return axios({
      url: "/admin/live/member_live/shelf",
      method: "post",
      data,
    });
  },
  // 数字市场=直播模板-状态
  delLive(id) {
    return axios({
      url: "/admin/live/member_live/"+id,
      method: "delete",
    });
  },
  // 数字市场=直播模板-编辑
  putLive(data,id) {
    return axios({
      url: "/admin/live/member_live/"+id,
      method: "put",
      data,
    });
  },
}
