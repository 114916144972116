// 素材管理
import axios from '../request/request.js'

export default {
  // 素材管理=分类管理-列表
  materialCategory(data) {
    return axios({
      url: "/admin/material_category",
      method: "get",
      data,
    });
  },
  // 素材管理=分类管理-修改状态
  categoryChangeStatus(data) {
    return axios({
      url: "/admin/material_category/change_status",
      method: "post",
      data,
    });
  },
  // 素材管理=分类管理-删除
  delMaterialCategory(data, id) {
    return axios({
      url: "/admin/material_category/" + id,
      method: "delete",
      data,
    });
  },
  // 素材管理=分类管理-编辑
  putMaterialCategory(data, id) {
    return axios({
      url: "/admin/material_category/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=分类管理-添加
  postMaterialCategory(data) {
    return axios({
      url: "/admin/material_category",
      method: "post",
      data,
    });
  },
  // 素材管理=公屏-列表
  getMaterialScreen(data) {
    return axios({
      url: "/admin/material_screen",
      method: "get",
      data,
    });
  },
  // 素材管理=公屏-分类
  getMaterialGetCat(data) {
    return axios({
      url: "/admin/material_screen/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=公屏-状态
  MaterialChangeStatus(data) {
    return axios({
      url: "/admin/material_screen/change_status",
      method: "post",
      data,
    });
  },
  // 素材管理=公屏-删除
  delMaterialScreen(data, id) {
    return axios({
      url: "/admin/material_screen/" + id,
      method: "delete",
      data,
    });
  },
  // 素材管理=公屏-添加
  postMaterialScreen(data) {
    return axios({
      url: "/admin/material_screen",
      method: "post",
      data,
    });
  },
  // 素材管理=公屏-编辑
  putMaterialScreen(data, id) {
    return axios({
      url: "/admin/material_screen/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=背景-分类
  backgroupCategory(data) {
    return axios({
      url: "/admin/material_backgroup/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=背景-列表
  backgroupList(data) {
    return axios({
      url: "/admin/material_backgroup",
      method: "get",
      data,
    });
  },
  // 素材管理=背景-添加
  backgroupListAdd(data) {
    return axios({
      url: "/admin/material_backgroup",
      method: "post",
      data,
    });
  },
  // 素材管理=背景-编辑
  backgroupListEdit(data, id) {
    return axios({
      url: "/admin/material_backgroup/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=背景-删除
  backgroupListDel(id) {
    return axios({
      url: "/admin/material_backgroup/" + id,
      method: "delete",
    });
  },
  // 素材管理=背景-开关
  backgroupListChange(data) {
    return axios({
      url: "/admin/material_backgroup/change_status",
      method: "post",
      data
    });
  },
  // 素材管理=图片-分类
  imageCategory(data) {
    return axios({
      url: "/admin/material_image/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=图片-列表
  imageList(data) {
    return axios({
      url: "/admin/material_image",
      method: "get",
      data,
    });
  },
  // 素材管理=图片-添加
  imageListAdd(data) {
    return axios({
      url: "/admin/material_image",
      method: "post",
      data,
    });
  },
  // 素材管理=图片-编辑
  imageListEdit(data, id) {
    return axios({
      url: "/admin/material_image/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=图片-删除
  imageListDel(id) {
    return axios({
      url: "/admin/material_image/" + id,
      method: "delete",
    });
  },
  // 素材管理=图片-开关
  imageListChange(data) {
    return axios({
      url: "/admin/material_image/change_status",
      method: "post",
      data
    });
  },
  // 素材管理=音乐-分类
  musicCategory(data) {
    return axios({
      url: "/admin/material_music/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=音乐-列表
  musicList(data) {
    return axios({
      url: "/admin/material_music",
      method: "get",
      data,
    });
  },
  // 素材管理=音乐-添加
  musicListAdd(data) {
    return axios({
      url: "/admin/material_music",
      method: "post",
      data,
    });
  },
  // 素材管理=音乐-编辑
  musicListEdit(data, id) {
    return axios({
      url: "/admin/material_music/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=音乐-删除
  musicListDel(id) {
    return axios({
      url: "/admin/material_music/" + id,
      method: "delete",
    });
  },
  // 素材管理=音乐-开关
  musicListChange(data) {
    return axios({
      url: "/admin/material_music/change_status",
      method: "post",
      data
    });
  },
  // 素材管理=视频-分类
  videoCategory(data) {
    return axios({
      url: "/admin/material_video/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=视频-列表
  videoList(data) {
    return axios({
      url: "/admin/material_video",
      method: "get",
      data,
    });
  },
  // 素材管理=视频-添加
  videoListAdd(data) {
    return axios({
      url: "/admin/material_video",
      method: "post",
      data,
    });
  },
  // 素材管理=视频-编辑
  videoListEdit(data, id) {
    return axios({
      url: "/admin/material_video/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=视频-删除
  videoListDel(id) {
    return axios({
      url: "/admin/material_video/" + id,
      method: "delete",
    });
  },
  // 素材管理=视频-开关
  videoListChange(data) {
    return axios({
      url: "/admin/material_video/change_status",
      method: "post",
      data
    });
  },
  // 素材管理=文本-分类
  textCategory(data) {
    return axios({
      url: "/admin/material_text/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=文本-列表
  textList(data) {
    return axios({
      url: "/admin/material_text",
      method: "get",
      data,
    });
  },
  // 素材管理=文本-添加
  textListAdd(data) {
    return axios({
      url: "/admin/material_text",
      method: "post",
      data,
    });
  },
  // 素材管理=文本-编辑
  textListEdit(data, id) {
    return axios({
      url: "/admin/material_text/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=文本-删除
  textListDel(id) {
    return axios({
      url: "/admin/material_text/" + id,
      method: "delete",
    });
  },
  // 素材管理=文本-开关
  textListChange(data) {
    return axios({
      url: "/admin/material_text/change_status",
      method: "post",
      data
    });
  },

  // 素材管理=实时挂件-获取分类列表
  material_clock_cat(data) {
    return axios({
      url: "/admin/material_clock/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=实时挂件-添加
  material_clock_add(data) {
    return axios({
      url: "/admin/material_clock",
      method: "post",
      data,
    });
  },
  // 素材管理=实时挂件-获取列表的内容
  material_clock_list(data) {
    return axios({
      url: "/admin/material_clock",
      method: "get",
      data,
    });
  },
  // 素材管理=实时挂件-更改状态
  material_clock_status(data) {
    return axios({
      url: "/admin/material_clock/change_status",
      method: "post",
      data,
    });
  },
  // 素材管理=实时挂件-编辑
  material_clock_edit(id, data) {
    return axios({
      url: "/admin/material_clock/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=实时挂件-删除
  material_clock_delete(id) {
    return axios({
      url: "/admin/material_clock/" + id,
      method: "delete",
    });
  },
  // 素材管理=滤镜-分类
  material_filter_cat(data) {
    return axios({
      url: "/admin/material_filter/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=滤镜-获取列表的内容
  material_filter_list(data) {
    return axios({
      url: "/admin/material_filter",
      method: "get",
      data,
    });
  },
  // 素材管理=滤镜-添加
  material_filter_add(data) {
    return axios({
      url: "/admin/material_filter",
      method: "post",
      data,
    });
  },
  // 素材管理=滤镜-编辑
  material_filter_edit(id, data) {
    return axios({
      url: "/admin/material_filter/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=滤镜-更改状态
  material_filter_status(data) {
    return axios({
      url: "/admin/material_filter/change_status",
      method: "post",
      data,
    });
  },
  // 素材管理=滤镜-删除
  material_filter_delete(id) {
    return axios({
      url: "/admin/material_filter/" + id,
      method: "delete",
    });
  },

  // 素材管理=字母-分类
  material_caption_cat(data) {
    return axios({
      url: "/admin/material_caption/get_cat",
      method: "get",
      data,
    });
  },
  // 素材管理=字母-获取列表的内容
  material_caption_list(data) {
    return axios({
      url: "/admin/material_caption",
      method: "get",
      data,
    });
  },
  // 素材管理=滤镜-添加
  material_caption_add(data) {
    return axios({
      url: "/admin/material_caption",
      method: "post",
      data,
    });
  },
  // 素材管理=滤镜-删除
  material_caption_del(id) {
    return axios({
      url: "/admin/material_caption/" + id,
      method: "delete",
    });
  },
  // 素材管理=滤镜-编辑
  material_caption_edit(id, data) {
    return axios({
      url: "/admin/material_caption/" + id,
      method: "put",
      data,
    });
  },
  // 素材管理=滤镜-更改状态
  material_caption_status(data) {
    return axios({
      url: "/admin/material_caption/change_status",
      method: "post",
      data,
    });
  },
}
