<!-- @Author: Yu_Bo -->
<template>
  <div class='digital_person'>
    <el-container class="digital_person_container">
      <el-aside width='120px' class="aside_left">
        <aside-nav :leftList='left_list' :leftIndex='left_index'></aside-nav>
      </el-aside>
      <el-container>
        <el-header height='56px' class="vue_main">
          <header-top :topList='top_list' :topIndex='top_index' @topClick='topClickBtn'></header-top>
        </el-header>
        <el-main class="scroll_bar">
          <section class="main_content">
            <transition name="fade-transform" mode="out-in">
              <router-view class="vue_main" />
            </transition>
          </section>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import { mapState } from "vuex"
  // import {menuData} from '@/utils/menu'//路由数据
  import AsideNav from './components/aside_nav.vue'//左侧
  import HeaderTop from './components/header_top.vue'//右侧顶部
  export default {
    name: "FrameMain",
    components: {AsideNav,HeaderTop},
    props: {},
    data() {
      return {
        left_index:0,
        left_list:[],
        top_index:0,
        top_list:[],
      }
    },
    computed: {
      ...mapState({
          token: state => state.token,
          roleList: state => state.roleList,
          loginShow: state => state.loginShow,
      }),
    },
    watch: {
      '$route'(to, from) {
        this.refreshBtn()
      }
    },
    created() {
      this.top_list=this.roleList
      this.refreshBtn()
    },
    mounted() {
      if(this.token && !this.loginShow){
        this.$store.dispatch('menusList')
      }
    },
    methods: {
      refreshBtn(){
        var arr = this.findParentsPath(this.roleList,this.$route.path,[])
        this.top_index = arr[0]
        this.left_index = arr[1]
        this.left_list = this.roleList[this.top_index].children
      },
      // 获取当前路由下标
      findParentsPath(arr,url,index){
        for (let i = 0; i < arr.length; i++) {
          let parentIndex = [...index,i]
          if(arr[i].component_path==url){
            return parentIndex.length === 1 ? this.findParentsPath(arr[i].children, url, parentIndex) : parentIndex
          }else if(arr[i].children && arr[i].children.length){
            let str = this.findParentsPath(arr[i].children, url, parentIndex)
            if(str) return str
          }
        }
      },
      // 点击顶部导航
      topClickBtn(index){
        this.$router.push(this.top_list[index].children[0].component_path);
      }
    },
  }
</script>

<style lang='scss' scoped>
  .digital_person {
    width: 100%;
    height: 100%;
    background: #EEF4FF;

    .digital_person_container {
      width: 100%;
      height: 100%;

      .aside_left {
        overflow: visible;
      }

      .el-header {
        padding: 0;
      }

      .el-main{
        width: 100%;
        height: 100%;
        padding: 0;
        background: #fff;
        border-radius: 10px 0 0 0;
      }

      .main_content{
        width: 100%;
      }
    }
  }
</style>
