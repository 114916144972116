<!-- @Author: Yu_Bo -->
<template>
  <div class="point_out zc_dialog_tip">
    <el-dialog custom-class="zc_dialog_tip" :style="[allStyle]" :visible.sync="dialogVisible" :width="dialogWidth"
      :close-on-click-modal='false' append-to-body>
      <div class="title" slot="title">
        <i v-if="iconShow" class="el-icon-warning" :style="[iconStyle]"></i>
        <div class="name">{{ dialogTitle }}</div>
      </div>
      <slot></slot>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="cancelShow" class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button v-if="affirmShow" class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: {//类型
      type: String,
      defaul: ''
    },
    dialogTitle: {//标题
      type: String,
      defaul: '提示'
    },
    dialogWidth: {//宽度
      type: String,
      defaul: '30%'
    },
    iconShow: {//图标是否显示
      type: Boolean,
      defaul: false
    },
    iconColor: {//图标颜色
      type: String,
      defaul: '#333'
    },
    borderRadius: {//圆角
      type: String,
      defaul: '2px'
    },
    cancelShow: {//取消按钮是否显示
      type: Boolean,
      defaul: true
    },
    affirmShow: {//确认按钮是否显示
      type: Boolean,
      defaul: true
    }
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    allStyle() {
      return {
        '--borderRadius': this.borderRadius
      }
    },
    iconStyle() {
      return {
        color: this.iconColor
      }
    },
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 打开弹框
    openDialogBtn() {
      this.dialogVisible = true
    },
    // 取消
    cancelBtn() {
      this.dialogVisible = false
    },
    // 确定
    affirmBtn() {
      this.dialogVisible = false
      // 退出
      if (this.type == 'quit') {
        this.$emit('close')
      }
      // 删除账户
      if (this.type == 'accountDel') {
        this.$emit('close')
      }
      // 删除角色
      if (this.type == 'roleDel') {
        this.$emit('close')
      }
      // 删除角色
      if (this.type == 'memberDel') {
        this.$emit('close')
      }
      // 声音克隆
      if(this.type=='sound'){
        this.$emit('close')
      }
      // 模型训练
      if(this.type=='model'){
        this.$emit('close')
      }
      // 删除素材管理
      if(this.type=='material'){
        this.$emit('close')
      }
      // 删除数字市场
      if(this.type=='market'){
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang='scss' scoped>
::v-deep .el-dialog {
  border-radius: var(--borderRadius);
}
.point_out,
.zc_dialog_tip {

  .title {
    display: flex;
    align-items: center;

    .el-icon-warning {
      font-size: 24px;
    }

    .name {
      padding-left: 10px;
      padding-bottom: 3px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
