const userInfo = {
  namespaced: true,
  state: {// 储存的数据
    user_info: localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info')) : ''
  },
  mutations: { // 修改
    // 储存用户信息
    SET_USER_INFO(state, info) {
     	state.user_info = JSON.parse(info)
      localStorage.user_info = info
    },
    // 清除用户信息
    DEL_USER_INFO(state, info) {
      state.user_info = ''
      localStorage.removeItem('user_info')
    },
  },
  actions: { // 发起异步请求

  },

};
export default userInfo;
