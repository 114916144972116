// purview-api
import axios from '../request/request.js'
//
export default {
  // 角色列表下拉数据
  systemUserParams(data) {
    return axios({
      url: "/admin/system/user-params",
      method: "get",
      data,
    });
  },
  // 账号列表
  systemUser(data) {
    return axios({
      url: "/admin/system/user",
      method: "get",
      data,
    });
  },
  // 修改状态
  systemUserStatus(data) {
    return axios({
      url: "/admin/system/user/status",
      method: "post",
      data,
    });
  },
  // 添加
  postSystemUser(data) {
    return axios({
      url: "/admin/system/user",
      method: "post",
      data,
    });
  },
  // 修改
  putSystemUser(data,id) {
    return axios({
      url: "/admin/system/user/"+id,
      method: "put",
      data,
    });
  },
  // 删除
  deleteSystemUser(data,id) {
    return axios({
      url: "/admin/system/user/"+id,
      method: "delete",
    });
  },
  // 列表
  systemRole(data) {
    return axios({
      url: "/admin/system/role",
      method: "get",
      data,
    });
  },
  // 修改状态
  systemRoleStatus(data) {
    return axios({
      url: "/admin/system/role/status",
      method: "post",
      data,
    });
  },
  // 删除
  delSystemRole(data,id) {
    return axios({
      url: "/admin/system/role/"+id,
      method: "delete",
    });
  },
  // 详情
  getSystemRole(data,id) {
    return axios({
      url: "/admin/system/role/"+id,
      method: "get",
      data,
    });
  },
  // 修改
  putSystemRole(data,id) {
    return axios({
      url: "/admin/system/role/"+id,
      method: "put",
      data,
    });
  },
  // 添加
  postSystemRole(data) {
    return axios({
      url: "/admin/system/role",
      method: "post",
      data,
    });
  },
  // 角色菜单列表
  roleMenuList(data) {
    return axios({
      url: "/admin/system/menu/role_menu_list",
      method: "get",
      data,
    });
  },
  // 操作日志
  systemLog(data) {
    return axios({
      url: "/admin/system/log",
      method: "get",
      data,
    });
  },
  // 账号列表导出
  userExport(data) {
    return axios({
      url: "/admin/system/user/export",
      method: "get",
      data,
      responseType:'blob'
    });
  },
  // 日志列表导出
  logExport(data) {
    return axios({
      url: "/admin/system/log/export",
      method: "get",
      data,
      responseType:'blob'
    });
  }
}
