// 公共api
import axios from '../request/request.js'
//
export default {
  // 获取活动列表
  activityList(data) {
    return axios({
      url: "/admin/activity/activity_list",
      method: "get",
      data,
    });
  },
  // 修改活动状态
  activityStatus(data) {
    return axios({
      url: "/admin/activity/changeStatus",
      method: "post",
      data,
    });
  },
  // 获取活动详情
  activityInfo(id) {
    return axios({
      url: "/admin/activity/activity_list/" + id,
      method: "get",
    });
  },
  // 获取活动列表添加
  activityAdd(data) {
    return axios({
      url: "/admin/activity/activity_list",
      method: "post",
      data,
    });
  },
  // 获取活动列表编辑
  activityEdit(data, id) {
    return axios({
      url: "/admin/activity/activity_list/" + id,
      method: "put",
      data,
    });
  },
  // 获取活动列表删除
  activityDel(id) {
    return axios({
      url: "/admin/activity/activity_list/" + id,
      method: "delete",
    });
  },


  // 广告列表
  advertList(data) {
    return axios({
      url: "/admin/activity/activity_describe",
      method: "get",
      data,
    });
  },
  // 添加广告列表
  advertAdd(data) {
    return axios({
      url: "/admin/activity/activity_describe",
      method: "post",
      data,
    });
  },
  // 修改广告列表
  advertEdit(data, id) {
    return axios({
      url: "/admin/activity/activity_describe/" + id,
      method: "put",
      data,
    });
  },
  // 删除广告列表
  advertDel(id) {
    return axios({
      url: "/admin/activity/activity_describe/" + id,
      method: "delete",
    });
  },



  // 课程目录
  catalogList(data) {
    return axios({
      url: "/admin/activity/activity_catalogue",
      method: "get",
      data,
    });
  },
  // 添加课程目录
  catalogAdd(data) {
    return axios({
      url: "/admin/activity/activity_catalogue",
      method: "post",
      data,
    });
  },

  // 修改课程目录
  catalogEdit(data, id) {
    return axios({
      url: "/admin/activity/activity_catalogue/" + id,
      method: "put",
      data,
    });
  },

  // 删除课程目录
  catalogDel(id) {
    return axios({
      url: "/admin/activity/activity_catalogue/" + id,
      method: "delete",
    });
  },




  // 活动课程
  activityCatalogList(data) {
    return axios({
      url: "/admin/activity/activity_course",
      method: "get",
      data,
    });
  },
  // 活动课程详情
  activityCatalogInfo(id) {
    return axios({
      url: "/admin/activity/activity_course/" + id,
      method: "get",
    });
  },
  // 添加活动课程
  activityCatalogAdd(data) {
    return axios({
      url: "/admin/activity/activity_course",
      method: "post",
      data,
    });
  },

  // 修改活动课程
  activityCatalogEdit(data, id) {
    return axios({
      url: "/admin/activity/activity_course/" + id,
      method: "put",
      data,
    });
  },

  // 删除活动课程
  activityCatalogDel(id) {
    return axios({
      url: "/admin/activity/activity_course/" + id,
      method: "delete",
    });
  },

  // 置顶、取消置顶
  activityIsTop(data) {
    return axios({
      url: "/admin/activity/is_top",
      method: "post",
      data
    });
  },
  // 活动列表排序
  activitySort(data) {
    return axios({
      url: "/admin/activity/sort",
      method: "post",
      data
    });
  },
}
