import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from '@/store/modules/user'
import publicApi from '@/axiosApi/public'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {// 储存的数据
    token: localStorage.getItem('token') || '',
    roleList: localStorage.getItem('roleList')?JSON.parse(localStorage.getItem('roleList')) : [],
    loginShow:false,
  },
  mutations: {// 修改
    // 储存token
    SET_TOKEN(state, token) {
    	state.token = token
    	localStorage.token = token
    },
    // 清除token
    DEL_TOKEN(state, token) {
    	state.token = ''
    	localStorage.removeItem('token')
    },
    // 储存路由
    SET_ROLE(state, menus) {
    	state.loginShow = true
    	state.roleList = JSON.parse(menus)
    	localStorage.roleList = menus
    },
    // 清除路由
    DEL_ROLE(state, menus) {
    	state.roleList = ''
    	localStorage.removeItem('roleList')
    },
  },
  actions: {// 发起异步请求
    menusList({ commit }) {
    	publicApi.menuList().then(res => {
    		if (res.code == 1000) {
    			commit('SET_ROLE', JSON.stringify(res.result))
    		}
    	})
    },
  },
  modules: {// 模块拆分
    userInfo,
  }
})
