// user-api
import axios from '../request/request.js'
//
export default {
  getConfig(data) {
    return axios({
      url: "/miniapp/v1/page/config",
      method: "get",
      data,
    });
  },
  // 用户 === 列表
  getMemberList(data) {
    return axios({
      url: "/admin/member/list",
      method: "get",
      data,
    });
  },
  // 用户 === 列表筛选
  getMemberListParams(data) {
    return axios({
      url: "/admin/member/params",
      method: "get",
      data,
    });
  },
  // 用户 === 详情
  getMemberDetails(data) {
    return axios({
      url: "/admin/member/info",
      method: "get",
      data,
    });
  },
  // 用户 === 状态
  memberStatusEdit(data) {
    return axios({
      url: "/admin/member/status",
      method: "post",
      data,
    });
  },
  // 用户 === 邀请码
  memberInvit(data) {
    return axios({
      url: "/admin/member/invit",
      method: "post",
      data,
    });
  },
  // 用户 === 统计
  memberStat(data) {
    return axios({
      url: "/admin/member/stat",
      method: "get",
      data,
    });
  },
  // 用户 === 充值订单列表
  memberCustomerOrder(data) {
    return axios({
      url: "/admin/member/customer_order",
      method: "get",
      data,
    });
  },
  // 用户 === 消耗列表
  memberCustomerConsumeOrder(data) {
    return axios({
      url: "/admin/member/customer_consume_order",
      method: "get",
      data,
    });
  },
  // 用户 === 列表导出
  memberExport(data) {
    return axios({
      url: "/admin/member/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 代理列表
  getAgencyList(data) {
    return axios({
      url: "/admin/agency/list",
      method: "get",
      data,
    });
  },
  // 代理列表筛选
  getAgencyListParams(data) {
    return axios({
      url: "/admin/agency/params",
      method: "get",
      data,
    });
  },
  // 代理详情
  getAgencyDetails(data) {
    return axios({
      url: "/admin/agency/info",
      method: "get",
      data,
    });
  },
  // 代理编辑
  agencyUpdate(data) {
    return axios({
      url: "/admin/agency/update",
      method: "post",
      data,
    });
  },
  // 代理统计
  agencyStat(data) {
    return axios({
      url: "/admin/agency/stat",
      method: "get",
      data,
    });
  },
  // 代理充值订单列表
  agencyCustomerOrder(data) {
    return axios({
      url: "/admin/agency/customer_order",
      method: "get",
      data,
    });
  },
  // 代理消耗列表
  agencyCustomerConsumeOrder(data) {
    return axios({
      url: "/admin/agency/customer_consume_order",
      method: "get",
      data,
    });
  },
  // 代理 === 列表导出
  agencyExport(data) {
    return axios({
      url: "/admin/agency/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 代理申请列表
  getAgencyApplyList(data) {
    return axios({
      url: "/admin/agency_apply",
      method: "get",
      data,
    });
  },
  // 代理申请列表筛选
  getAgencyApplyListParams(data) {
    return axios({
      url: "/admin/agency_apply/params",
      method: "get",
      data,
    });
  },
  // 代理申请详情
  getAgencyApplyDetails(data) {
    return axios({
      url: "/admin/agency_apply/" + data,
      method: "get",
      // data,
    });
  },
  // 代理审核
  agencyApplyAudit(data) {
    return axios({
      url: "/admin/agency_apply/audit",
      method: "post",
      data,
    });
  },
  // 代理审核 === 列表导出
  agencyApplyExport(data) {
    return axios({
      url: "/admin/apply/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  //  kol列表=审核
  getKolApplyList(data) {
    return axios({
      url: "/admin/kol_apply",
      method: "get",
      data,
    });
  },
  //  kol列表=编辑
  getKolEdit(data, id) {
    return axios({
      url: "/admin/kol_apply/" + id,
      method: "put",
      data,
    });
  },
  // kol列表筛选=审核
  getKolApplyListParams(data) {
    return axios({
      url: "/admin/kol_apply/params",
      method: "get",
      data,
    });
  },
  // kol详情=审核
  getKolApplyDetails(data) {
    return axios({
      url: "/admin/kol_apply/" + data,
      method: "get",
      // data,
    });
  },
  // kol审核=审核
  kolApplyAudit(data) {
    return axios({
      url: "/admin/kol_apply/audit",
      method: "post",
      data,
    });
  },
  // 充值订单列表筛选
  getRechargeParams(data) {
    return axios({
      url: "/admin/member/recharge_params",
      method: "get",
      data,
    });
  },
  // 消耗订单列表筛选
  getConsumeParams(data) {
    return axios({
      url: "/admin/member/consume_params",
      method: "get",
      data,
    });
  },
  // 充值套餐 参数
  rechargeType(data) {
    return axios({
      url: "/admin/agency/recharge_type",
      method: "post",
      data,
    });
  },
  // 代理充值套餐
  agencyRecharge(data) {
    return axios({
      url: "/admin/agency/recharge",
      method: "post",
      data,
    });
  },
  // 用户充值套餐
  memberRecharge(data) {
    return axios({
      url: "/admin/member/recharge",
      method: "post",
      data,
    });
  },
  // 用户充值套餐
  getKolList(data) {
    return axios({
      url: "/admin/kol_pass/index",
      method: "get",
      data,
    });
  },
  // kol审核列表导出
  kolApplyExport(data) {
    return axios({
      url: "/admin/kol_apply/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // kol列表导出
  kolPassExport(data) {
    return axios({
      url: "/admin/kol_pass/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 角色列表
  adminIndex(data) {
    return axios({
      url: "/admin/member_role/admin_index",
      method: "get",
      data,
    });
  },
  // 权限树
  memberRoleTree(data) {
    return axios({
      url: "/admin/member_role/tree",
      method: "get",
      data,
    });
  },
  // 修改权限
  adminUpdate(data) {
    return axios({
      url: "/admin/member_role/admin_update",
      method: "post",
      data,
    });
  },
  // 用户所关联企业
  memberCorps(data) {
    return axios({
      url: "/admin/member/member_corps/"+data,
      method: "get",
    });
  },
  // 激活直播
  memberOpenLive(data) {
    return axios({
      url: "/admin/member/open_live",
      method: "post",
      data,
    });
  },
  // 设置、取消直播管理员
  setLiveAdmin(data) {
    return axios({
      url: "/admin/member/set_live_admin",
      method: "post",
      data,
    });
  },
}
