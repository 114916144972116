// 公共api
import axios from '../request/request.js'
//
export default {
    //会员组列表
    memberGroupList(data) {
        return axios({
            url: "/admin/miniapp/memberGroup",
            method: "get",
            data,
        });
    },
    // 会员组添加
    memberGroupAdd(data) {
        return axios({
            url: "/admin/miniapp/memberGroup",
            method: "post",
            data,
        });
    },
    // 会员组修改
    memberGroupEdit(data, id) {
        return axios({
            url: "/admin/miniapp/memberGroup/" + id,
            method: "put",
            data,
        });
    },
    // 会员组删除
    memberGroupDel(id) {
        return axios({
            url: "/admin/miniapp/memberGroup/" + id,
            method: "delete",
        });
    },
    // 会员组状态修改
    memberGroupStatus(data) {
        return axios({
            url: "/admin/miniapp/memberGroup/changeStatus",
            method: "post",
            data,
        });
    },
    // 会员列表
    vipUser(data) {
        return axios({
            url: "/admin/miniapp/vip_user",
            method: "get",
            data,
        });
    },

    //会员清单列表
    memberList(data) {
        return axios({
            url: "/admin/miniapp/memberList",
            method: "get",
            data,
        });
    },
    // 会员清单添加
    memberListAdd(data) {
        return axios({
            url: "/admin/miniapp/memberList",
            method: "post",
            data,
        });
    },
    // 会员清单修改
    memberListEdit(data, id) {
        return axios({
            url: "/admin/miniapp/memberList/" + id,
            method: "put",
            data,
        });
    },
    // 会员清单删除
    memberListDel(id) {
        return axios({
            url: "/admin/miniapp/memberList/" + id,
            method: "delete",
        });
    },
    // 会员清单状态修改
    memberListStatus(data) {
        return axios({
            url: "/admin/miniapp/memberList/changeStatus",
            method: "post",
            data,
        });
    },
    //会员设置参数
    memberConfigParams(data) {
        return axios({
            url: "/admin/miniapp/vip_config/params",
            method: "get",
            data,
        });
    },
    //会员设置列表
    memberConfigList(data) {
        return axios({
            url: "/admin/miniapp/vip_config",
            method: "get",
            data,
        });
    },
    // 会员清单添加
    memberConfigAdd(data) {
        return axios({
            url: "/admin/miniapp/vip_config",
            method: "post",
            data,
        });
    },
    // 会员清单修改
    memberConfigEdit(data, id) {
        return axios({
            url: "/admin/miniapp/vip_config/" + id,
            method: "put",
            data,
        });
    },
    // 会员清单删除
    memberConfigDel(id) {
        return axios({
            url: "/admin/miniapp/vip_config/" + id,
            method: "delete",
        });
    },
    // 会员清单状态修改
    memberConfigStatus(data) {
        return axios({
            url: "/admin/miniapp/vip_config/status",
            method: "post",
            data,
        });
    },
    // 用户导入
    import_user(data) {
        return axios({
            url: "/admin/miniapp/import_user",
            method: "post",
            data,
            'Headers': {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    // 用户列表参数
    getParams(data) {
        return axios({
            url: "/admin/miniapp/params",
            method: "get",
            data,
        });
    },
    // 用户列表
    user_list(data) {
        return axios({
            url: "/admin/miniapp/user_list",
            method: "get",
            data,
        });
    },
    // 用户列表
    change_status(data) {
        return axios({
            url: "/admin/miniapp/change_status",
            method: "post",
            data,
        });
    },
    // 用户详情
    user_info(data,id) {
        return axios({
            url: "/admin/miniapp/user_info/"+id,
            method: "get",
            data,
        });
    },
    // 修改vip时间
    change_vip_time(data) {
        return axios({
            url: "/admin/miniapp/change_vip_time",
            method: "post",
            data,
        });
    },
}
