export function succMsg(text) {
  this.$message({
    message: text,
    type: 'success',
    // offset: document.documentElement.clientHeight/2-50,
    duration: 2000
  });
}
export function warMsg(text) {
  this.$message({
    message: text,
    type: 'warning',
    // offset: document.documentElement.clientHeight/2-50,
    duration: 2000
  });
}
export function textMsg(text) {
  this.$message({
    message: text,
    // offset: document.documentElement.clientHeight/2-50,
    duration: 2000
  });
}
export function errMsg(text) {
  this.$message({
    message: text,
    type: 'error',
    // offset: document.documentElement.clientHeight/2-50,
    duration: 2000
  });
}
export default {
  succMsg, //成功
  warMsg, //警告
  textMsg, //消息
  errMsg, //错误
}
