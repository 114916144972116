// 公共api
import axios from '../request/request.js'
//
export default {
  // 登录
  userLogin(data) {
    return axios({
      url: "/admin/system/user/login",
      method: "post",
      data,
    });
  },
  // 获取菜单
  menuList(data) {
    return axios({
      url: "/admin/system/menu/menu-list",
      method: "get",
      data,
    });
  },
  // 上传图片
  uploadFile(data) {
    return axios({
      url: "/admin/utils/upload_file",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // 上传语音
  uploadAudio(data) {
    return axios({
      url: "/admin/tts_list/upload_audio",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // 上传视频
  uploadVideo(data) {
    return axios({
      url: "/admin/model/upload_video",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // 上传文件
  uploadReInfo(data) {
    return axios({
      url: "/admin/utils/upload_re_info",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}
