// 训练管理
import axios from '../request/request.js'
//
export default {
  //模型筛选
  modelParams(data) {
    return axios({
      url: "/admin/model/params",
      method: "get",
      data,
    });
  },
  //模型列表
  modelList(data) {
    return axios({
      url: "/admin/model_list",
      method: "get",
      data,
    });
  },
  //通知充值
  callRecharge(data) {
    return axios({
      url: "/admin/model/call_recharge",
      method: "get",
      data,
    });
  },
  //上架
  modelShelf(data) {
    return axios({
      url: "/admin/model/shelf",
      method: "post",
      data,
    });
  },
  //筛选用户
  selectMember(data) {
    return axios({
      url: "/admin/model/select_member",
      method: "get",
      data,
    });
  },
  //筛选用户
  modelListPut(data,vid) {
    return axios({
      url: "/admin/model_list/"+vid,
      method: "put",
      data,
    });
  },
  //详情
  modelListDetails(data) {
    return axios({
      url: "/admin/model_list/"+data,
      method: "get",
    });
  },
  //审核
  modelAudit(data) {
    return axios({
      url: "/admin/model/audit",
      method: "post",
      data,
    });
  },
  // 重新上传
  uploadVideo(data) {
    return axios({
      url: "/admin/model/upload_video",
      method: "post",
      data,
      'Headers':{'Content-Type': 'multipart/form-data'}
    });
  },
  // 新增模型训练
  modelListPost(data) {
    return axios({
      url: "/admin/model_list",
      method: "post",
      data,
    });
  },
  // 重新上传保存
  upVideo(data) {
    return axios({
      url: "/admin/model/up_video",
      method: "post",
      data,
    });
  },
  // 克隆声音列表参数
  ttsListParams(data) {
    return axios({
      url: "/admin/tts_list/params",
      method: "get",
      data,
    });
  },
  // 克隆声音列表
  ttsList(data) {
    return axios({
      url: "/admin/tts_list",
      method: "get",
      data,
    });
  },
  // 克隆声音=通知充值
  ttsListCallRecharge(data) {
    return axios({
      url: "/admin/tts_list/call_recharge",
      method: "get",
      data,
    });
  },
  // 克隆声音=发布
  ttsListPublish(data) {
    return axios({
      url: "/admin/tts_list/publish",
      method: "post",
      data,
    });
  },
  // 克隆声音=筛选用户
  ttsListSelectMember(data) {
    return axios({
      url: "/admin/tts_list/select_member",
      method: "get",
      data,
    });
  },
  // 克隆声音=编辑客户信息
  ttsListPut(data) {
    return axios({
      url: "/admin/tts_list",
      method: "put",
      data,
    });
  },
  // 克隆声音=编辑客户信息
  ttsListDetails(data) {
    return axios({
      url: "/admin/tts_list/"+data,
      method: "get",
    });
  },
  // 克隆声音=编辑客户信息
  ttsListPost(data) {
    return axios({
      url: "/admin/tts_list",
      method: "post",
      data,
    });
  },
  // 克隆声音=重新上传保存
  ttsListUpAudio(data) {
    return axios({
      url: "/admin/tts_list/up_audio",
      method: "post",
      data,
    });
  },
  // 模特编辑
  drillFulfill(data) {
    return axios({
      url: "/admin/model/drill_fulfill",
      method: "post",
      data,
    });
  },
  // 模特编辑
  modelMlist(data) {
    return axios({
      url: "/admin/model/m_list",
      method: "get",
      data,
    });
  }
}
