<!-- @Author: Yu_Bo -->
<template>
	<div class='aside_nav'>
    <div class="logo">
      <img src="@/assets/images/img/logo_nav.png" alt="">
    </div>
    <div class="nav_list">
      <div class="list" :class="leftIndex==index?'list_active':''" v-for="(item,index) in leftList" :key="index" @click="leftNav(item.component_path)">
        <i class="iconfont i_icon" :class="item.icon"></i>
        <span>{{item.component_name}}</span>
      </div>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      leftIndex: {
          type: [String,Number],
          default: 0
      },
      leftList: {
          type: Array,
          default: []
      },
    },
		data() {
			return{
			}
		},
		computed: {
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 跳转
      leftNav(url){
        this.$router.push(url)
      }
    },
	}
</script>

<style lang='scss' scoped>
	.aside_nav{
    position: relative;
    width: 100%;
    height: 100%;
    background: url(~@/assets/images/img/left_background.png) no-repeat;
    background-size: 100% 100%;
    .logo{
      width: 100%;
      padding: 25px 0;
      img{
        display: block;
        width: 84px;
        height: 30px;
        margin: auto;
      }
    }
    .nav_list{
      width: 100%;
      .list{
        cursor: pointer;
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
        padding-left: 15px;
        color: #666666;
        display: flex;
        align-items: center;
        // justify-content: center;
        .i_icon{
          font-size: 18px;
        }
        span{
          padding-left: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
      .list_active{
        color: #2E4BF2;
        background: #FBFCFF;
      }
    }
	}
</style>
