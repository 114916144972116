// 素材管理
import axios from '../request/request.js'

export default {

  // 帮助中心=分类-下拉框列表
  materialCategory(data) {
    return axios({
      url: "/admin/material_category/pull_down_list",
      method: "get",
      data,
    });
  },



  // 帮助中心=分类-列表
  categoryList(data) {
    return axios({
      url: "/admin/material_category",
      method: "get",
      data,
    });
  },
  // 帮助中心=分类-添加
  categoryListAdd(data) {
    return axios({
      url: "/admin/material_category",
      method: "post",
      data,
    });
  },
  // 帮助中心=分类-编辑
  categoryListEdit(data, id) {
    return axios({
      url: "/admin/material_category/" + id,
      method: "put",
      data,
    });
  },
  // 帮助中心=分类-删除
  categoryListDel(id) {
    return axios({
      url: "/admin/material_category/" + id,
      method: "delete",
    });
  },
  // 帮助中心=分类-开关
  categoryListChange(data) {
    return axios({
      url: "/admin/material_category/change_status",
      method: "post",
      data
    });
  },


  // 帮助中心=文档-列表
  documentList(data) {
    return axios({
      url: "/admin/help/article",
      method: "get",
      data,
    });
  },
  // 帮助中心=文档-详情
  documentListInfo(id) {
    return axios({
      url: "/admin/help/article/" + id,
      method: "get",
    });
  },

  // 帮助中心=文档-添加
  documentListAdd(data) {
    return axios({
      url: "/admin/help/article",
      method: "post",
      data,
    });
  },
  // 帮助中心=文档-编辑
  documentListEdit(data, id) {
    return axios({
      url: "/admin/help/article/" + id,
      method: "put",
      data,
    });
  },
  // 帮助中心=文档-删除
  documentListDel(id) {
    return axios({
      url: "/admin/help/article/" + id,
      method: "delete",
    });
  },
  // 帮助中心=文档-开关
  documentListChange(data) {
    return axios({
      url: "/admin/help/article/status",
      method: "post",
      data
    });
  },




  // 帮助中心=专题管理-列表
  themeList(data) {
    return axios({
      url: "/admin/help/theme",
      method: "get",
      data,
    });
  },
  // 帮助中心=专题管理-添加
  themeListAdd(data) {
    return axios({
      url: "/admin/help/theme",
      method: "post",
      data,
    });
  },
  // 帮助中心=专题管理-编辑
  themeListEdit(data, id) {
    return axios({
      url: "/admin/help/theme/" + id,
      method: "put",
      data,
    });
  },
  // 帮助中心=专题管理-删除
  themeListDel(id) {
    return axios({
      url: "/admin/help/theme/" + id,
      method: "delete",
    });
  },
  // 帮助中心=专题管理-开关
  themeListChange(data) {
    return axios({
      url: "/admin/help/theme/status",
      method: "post",
      data
    });
  },


  // 帮助中心=专题管理-明细-文档列表
  themeDetailList(data, id) {
    return axios({
      url: "/admin/help/theme/" + id,
      method: "get",
      data,
    });
  },
  // 帮助中心=专题管理-明细-添加文档
  themeDetailAdd(data, id) {
    return axios({
      url: "/admin/help/theme/save_detail/" + id,
      method: "post",
      data,
    });
  },
  // 帮助中心=专题管理-明细-删除文档
  themeDetailDel(id) {
    return axios({
      url: "/admin/help/theme/delete_detail/" + id,
      method: "delete",
    });
  },
  // 帮助中心=专题管理-明细-修改排序
  changeWeigh(data) {
    return axios({
      url: "/admin/help/theme/set_detail_sort",
      method: "post",
      data,
    });
  },

  // 帮助中心=意见反馈-列表
  feedbackList(data) {
    return axios({
      url: "/admin/help/feedback",
      method: "get",
      data,
    });
  },
}
