// 作品管理
import axios from '../request/request.js'
//
export default {
  // 视频 === 列表
  getVideoList(data) {
    return axios({
      url: "/admin/video/admin_index",
      method: "get",
      data,
    });
  },
  // 视频 === 下架
  videoShelf(data) {
    return axios({
      url: "/admin/video/change",
      method: "post",
      data,
    });
  },
  // 视频/音频/绘画/漫画 === 列表筛选
  getDrawParams(data) {
    return axios({
      url: "/admin/draw/params",
      method: "get",
      data,
    });
  },
  // 音频 === 列表
  getAudioList(data) {
    return axios({
      url: "/admin/tts_create",
      method: "get",
      data,
    });
  },
  // 音频 === 下架
  audioShelf(data) {
    return axios({
      url: "/admin/tts_create/shelf",
      method: "post",
      data,
    });
  },
  // 绘画 === 列表
  getDrawList(data) {
    return axios({
      url: "/admin/draw",
      method: "get",
      data,
    });
  },
  // 绘画 === 下架
  drawShelf(data) {
    return axios({
      url: "/admin/draw/shelf",
      method: "post",
      data,
    });
  },
  // 漫画 === 列表
  getCartoonList(data) {
    return axios({
      url: "/admin/cartoon",
      method: "get",
      data,
    });
  },
  // 漫画 === 下架
  cartoonShelf(data) {
    return axios({
      url: "/admin/cartoon/shelf",
      method: "post",
      data,
    });
  },
}
