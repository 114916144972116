import axios from 'axios'
import store from '../store'
import router from '../router'
import baseUrl from './baseUrl.js'
import { Message } from 'element-ui';
// 配置请求默认接口，环境切换
if (process.env.NODE_ENV === 'development') {
  // 开发环境接口
  axios.defaults.baseURL = baseUrl.Base_dev_URL
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境接口
  axios.defaults.baseURL = baseUrl.Base_pro_URL
}
// 设置请求超时时间
axios.defaults.timeout = 120000
// 是否允许跨域携带cookie信息
axios.defaults.withCredentials = false;
// 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 拦截器 添加一个请求拦截器
axios.interceptors.request.use((config) => {
  // 配置请求头
  let type = config && config.Headers ? config?.Headers['Content-Type'] : 'application/json;charset=UTF-8'
  config.headers["Content-Type"] = type;
	if (config.url === '/admin/system/user/login') {} else {
		config.headers["Authorization"] = store.state.token; //把token添加到请求头
	}
	return config;
}, (error) => {
  // 请求出错
  return Promise.reject(error);
});

// 拦截器 添加一个响应拦截器
axios.interceptors.response.use((response) => {
  if (response.status === 200) {
    // 判断是二进制文件
    if(response.data instanceof Blob){
      donwold(response)
      return
    }
    // 正常请求返回
    if (response.data.code == 1003) { //未登录
      router.replace("/")
      return Promise.reject()
    } else if (response.data.code == 1004) { //没有权限
      router.replace("/403")
      return Promise.reject()
    } else {
      return response.data
    }
  } else {
    return Promise.reject(response);
  }
}, (error) => {
	if (error.response.status) {
		switch (error.response.status) {
			case 500:
				router.replace("/500")
				break;
			case 400:
				router.replace("/400")
				break;
			default:
		}
		return Promise.reject(error.response);
	} else {
		return Promise.reject(error)
	}
})
// 下载文件
function donwold(res){
  if(res.headers['content-disposition']){
    const link = document.createElement('a')
    let blob = new Blob([res.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'})
    link.style.display = 'none'
    link.href=URL.createObjectURL(blob)
    let filename = res.headers['content-disposition'].split(';filename=')[1]
    filename=decodeURI(filename)
    link.setAttribute('download',filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }else{
    var blob = res.data
    let reader = new FileReader();
    reader.readAsText(res.data)
    reader.onload = event => {
      var obj = JSON.parse(event.currentTarget.result)
      Message({
        message: obj.message,
        type: 'error',
        duration: 2000
      })
    }
  }
}

function http(config) {
  if (config.method.toLowerCase() == 'get') {
    config.params = config.data
  }
  return axios(config)
}
export default http;
