<!-- @Author: Yu_Bo -->
<template>
    <div>
        <div class="header_top">
            <div class="left">
                <!-- <div class="text" :class="topIndex==index?'text_active':''" v-for="(item,index) in topList" :key="index" @click="topNav(index)">
                    <span class="name">{{item.component_name}}</span>
                    <span class="line"></span>
                </div> -->
                <el-tabs v-model="tabKey" @tab-click='topNav'>
                    <el-tab-pane v-for="item in topList" :key='item.id'
                        :label="item.component_name" :name="item.id">
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="right">
                <el-badge is-dot class="new_txt">
                    <img class="new_icon" src="../../assets/images/icon/top_icon1.png" alt="">
                </el-badge>
                <img class="home_icon" src="../../assets/images/icon/top_icon2.png" alt="">
                <span class="line1"></span>
                <div class="header">
                    <img :src="userInfo.avatar || circleUrl">
                    <span class="name">{{userInfo.nickname}}</span>
                </div>
                <span class="line2"></span>
                <img class="quit_icon" @click="quitBtn" src="../../assets/images/icon/top_icon3.png" alt="">
            </div>
        </div>
        <point-out ref='dialogTip' type='quit' @close='quitClose' dialogTitle="提示" dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">确定退出当前账户吗？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import { mapState } from "vuex"
import PointOut from '@/components/point_out/point_out.vue'//提示
export default {
    components: { PointOut },
    props: {
        topIndex: {
            type: [String, Number],
            default: 0
        },
        topList: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            circleUrl: require('@/assets/images/img/header.png'),
            tabKey:"",
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo.user_info, // 用户信息
        }),
    },
    watch: {},
    created() { },
    mounted() { 
        this.$nextTick(()=>{
            if(this.topList.length){
                this.tabKey = this.topList[this.topIndex].id
            }else{
                setTimeout(()=>{
                    this.tabKey = this.topList[this.topIndex].id
                },1000)
            }
        })
    },
    methods: {
        // 切换
        topNav(item) {
            this.tabKey = item.name
            this.$emit('topClick', item.index)
        },
        // 退出登录
        quitBtn() {
            this.$refs.dialogTip.openDialogBtn()
        },
        quitClose() {
            this.$store.commit('DEL_TOKEN', '')
            this.$store.commit('userInfo/DEL_USER_INFO', '')
            this.$router.push('/login')
        },
        removeTab(){},
    },
}
</script>

<style lang='scss' scoped>
.header_top {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
        width: calc(100% - 350px);
        height: 100%;
        padding-left: 20px;
        overflow: hidden;
        ::v-deep .el-tabs{
            .el-tabs__header{
                margin: 0;
            }
            .el-tabs__nav-prev,.el-tabs__nav-next{
                line-height: 56px;
                font-size: 16px;
            }
            .el-tabs__nav-wrap::after {
                height: 0;
                background-color: none;
            }
            .el-tabs__active-bar {
                height: 0;
                background-color: none;
            }
            .el-tabs__item{
                height: 56px;
                line-height: 56px;
            }
        }
        .text {
            cursor: pointer;
            position: relative;
            padding: 0 20px;
            .name {
                display: block;
                width: 100%;
                line-height: 56px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
            }
            .line {
                position: absolute;
                bottom: 10px;
                left: 50%;
                display: none;
                width: 16px;
                height: 2px;
                margin-left: -8px;
                background: #2e4bf2;
                border-radius: 1px;
            }
        }
        .text_active {
            .name {
                color: #2e4bf2;
            }
            .line {
                display: block;
            }
        }
    }
    .right {
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .new_txt {
            width: 20px;
            height: 20px;
            img {
                cursor: pointer;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .home_icon {
            cursor: pointer;
            display: block;
            width: 20px;
            height: 20px;
            margin: 0 20px;
        }
        .line1 {
            width: 1px;
            height: 23px;
            background: #d9d9d9;
        }
        .header {
            padding: 0 20px;
            display: flex;
            align-items: center;
            img {
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
            .name {
                padding-left: 12px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }
        .line2 {
            width: 1px;
            height: 15px;
            background: #d9d9d9;
        }
        .quit_icon {
            cursor: pointer;
            display: block;
            width: 20px;
            height: 20px;
            margin-left: 20px;
        }
    }
}
</style>
