// setup-api
import axios from '../request/request.js'
//
export default {
  // 获取协议
  protocolConfigs(data) {
    return axios({
      url: "/admin/configs/protocol_configs",
      method: "get",
      data,
    });
  },
  // 修改协议
  saveProtocolConfigs(data) {
    return axios({
      url: "/admin/configs/save_protocol_configs",
      method: "post",
      data,
    });
  },
  // 系统设置  获取配置
  getConfigs(data) {
    return axios({
      url: "/admin/configs",
      method: "get",
      data,
    });
  },
  // 获取协议
  postConfigs(data) {
    return axios({
      url: "/admin/configs",
      method: "post",
      data,
    });
  },

  // 会员设置  列表
  memberConfig(data) {
    return axios({
      url: "/admin/recharge/member_config/params",
      method: "get",
      data,
    });
  },
  // 会员设置  参数
  memberList(data) {
    return axios({
      url: "/admin/recharge/member_config",
      method: "get",
      data,
    });
  },
  // 会员设置  添加
  memberAdd(data) {
    return axios({
      url: "/admin/recharge/member_config",
      method: "post",
      data,
    });
  },
  // 会员设置  编辑
  memberEdit(data, id) {
    return axios({
      url: "/admin/recharge/member_config/" + id,
      method: "put",
      data,
    });
  },
  // 会员设置  删除
  memberDel(id) {
    return axios({
      url: "/admin/recharge/member_config/" + id,
      method: "delete",
    });
  },
  // 会员设置  开关
  memberConfigStatus(data) {
    return axios({
      url: "/admin/recharge/member_config/status",
      method: "post",
      data,
    });
  },

  // 钻石充值  参数
  getParams(data) {
    return axios({
      url: "/admin/recharge/diamond_config/params",
      method: "get",
      data,
    });
  },
  // 钻石充值  列表
  diamondList(data) {
    return axios({
      url: "/admin/recharge/diamond_config",
      method: "get",
      data,
    });
  },
  // 钻石充值  添加
  diamondAdd(data) {
    return axios({
      url: "/admin/recharge/diamond_config",
      method: "post",
      data,
    });
  },
  // 钻石充值  编辑
  diamondEdit(data, id) {
    return axios({
      url: "/admin/recharge/diamond_config/" + id,
      method: "put",
      data,
    });
  },
  // 钻石充值  删除
  diamondDel(id) {
    return axios({
      url: "/admin/recharge/diamond_config/" + id,
      method: "delete",
    });
  },
  // 钻石充值  开关
  diamondConfigStatus(data) {
    return axios({
      url: "/admin/recharge/diamond_config/status",
      method: "post",
      data,
    });
  },

  // 会员码兑换  列表
  redeemCodeMember(data) {
    return axios({
      url: "/admin/recharge/redeem_code_member",
      method: "get",
      data,
    });
  },
  // 会员码类型参数
  getMemberParams(data) {
    return axios({
      url: "/admin/recharge/redeem_code_member/params",
      method: "get",
      data,
    });
  },
  // 添加会员码
  addMemberCode(data) {
    return axios({
      url: "/admin/recharge/redeem_code_member",
      method: "post",
      data,
    });
  },
  // 会员码明细
  getMemberList(data, id) {
    return axios({
      url: "/admin/recharge/redeem_code_member/" + id,
      method: "get",
      data,
    });
  },
  // 会员码明细  开关
  memberDetailStatus(data) {
    return axios({
      url: "/admin/recharge/redeem_code_member/detail_status",
      method: "post",
      data,
    });
  },
  // 充值码兑换  列表
  redeemCodeRecharge(data) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge",
      method: "get",
      data,
    });
  },
  // 充值码类型参数
  getRechargeParams(data) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge/params",
      method: "get",
      data,
    });
  },
  // 添加充值码
  addRechargeCode(data) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge",
      method: "post",
      data,
    });
  },
  // 充值码明细
  rechargeCodeInfo(data, id) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge/" + id,
      method: "get",
      data,
    });
  },
  // 充值码明细  开关
  rechargeDetailStatus(data) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge/detail_status",
      method: "post",
      data,
    });
  },
  // 获取面额
  getDiamondConfig(data) {
    return axios({
      url: "/admin/recharge/diamond_config",
      method: "get",
      data,
    });
  },
  // 会员码列表导出
  getMemberExport(data) {
    return axios({
      url: "/admin/recharge/redeem_code_member/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 会员码明细导出
  getMemberInfoExport(id) {
    return axios({
      url: "/admin/recharge/redeem_code_member/export/" + id,
      method: "get",
      responseType: 'blob'
    });
  },
  // 充值码列表导出
  getRechargeExport(data) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 充值码明细导出
  getRechargeInfoExport(id) {
    return axios({
      url: "/admin/recharge/redeem_code_recharge/export/" + id,
      method: "get",
      responseType: 'blob'
    });
  },
  // 训练码列表导出
  getTrainExport(data) {
    return axios({
      url: "/admin/recharge/redeem_code_training/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 训练码明细导出
  getTrainInfoExport(id) {
    return axios({
      url: "/admin/recharge/redeem_code_training/export/" + id,
      method: "get",
      responseType: 'blob'
    });
  },
  // 训练码类型参数
  getTrainParams(data) {
    return axios({
      url: "/admin/recharge/redeem_code_training/params",
      method: "get",
      data,
    });
  },
  // 训练兑换码列表
  redeemCodeTrain(data) {
    return axios({
      url: "/admin/recharge/redeem_code_training",
      method: "get",
      data,
    });
  },
  // 添加训练兑换码
  addTrainCode(data) {
    return axios({
      url: "/admin/recharge/redeem_code_training",
      method: "post",
      data,
    });
  },
  // 训练码明细
  getTrainList(data, id) {
    return axios({
      url: "/admin/recharge/redeem_code_training/" + id,
      method: "get",
      data,
    });
  },
  // 训练码明细  开关
  trainDetailStatus(data) {
    return axios({
      url: "/admin/recharge/redeem_code_training/detail_status",
      method: "post",
      data,
    });
  },
  // 版本列表
  adminVersion(data) {
    return axios({
      url: "/admin/version",
      method: "get",
      data,
    });
  },
  // 更新版本
  putVersion(data,id) {
    return axios({
      url: "/admin/version/"+id,
      method: "put",
      data,
    });
  },
}
