import './theme/index.css'
import Vue from 'vue'
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// 全局引入图片验证码组件
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
import App from './App.vue'
import router from './router'
import store from './store'
// 字体图标文件
import "./assets/iconfont/iconfont.css"
import './assets/iconfont/iconfont.js'
// 接口api
import publicApi from './axiosApi/public.js'
import userApi from './axiosApi/user.js'
import setupApi from './axiosApi/setup.js'
import purviewApi from './axiosApi/purview.js'
import trainApi from './axiosApi/train.js'
import worksApi from './axiosApi/works.js'
import activityApi from './axiosApi/activity.js'
import articleApi from './axiosApi/article.js'
import flowApi from './axiosApi/flow.js'
import memberApi from './axiosApi/member.js'
import materialApi from './axiosApi/material.js'
import marketApi from './axiosApi/market.js'
import helpApi from './axiosApi/help.js'
Vue.prototype.$publicApi = publicApi //公共接口
Vue.prototype.$userApi = userApi //用户接口
Vue.prototype.$setupApi = setupApi //设置接口
Vue.prototype.$purviewApi = purviewApi //权限接口
Vue.prototype.$trainApi = trainApi //训练管理
Vue.prototype.$worksApi = worksApi //作品管理
Vue.prototype.$activityApi = activityApi //活动管理
Vue.prototype.$articleApi = articleApi //文章管理
Vue.prototype.$flowApi = flowApi //流量圈
Vue.prototype.$memberApi = memberApi //会员组
Vue.prototype.$materialApi = materialApi //素材管理
Vue.prototype.$marketApi = marketApi //数字市场
Vue.prototype.$helpApi = helpApi //帮助中心

// 过滤器
import * as _filter from "@/filters/index"
Object.keys(_filter).forEach(item => {
  Vue.filter(item, _filter[item])
})
// 系统消息提示
import {
  succMsg,
  warMsg,
  textMsg,
  errMsg,
} from '@/utils/tip.js'
Vue.prototype.$succMsg = succMsg
Vue.prototype.$warMsg = warMsg
Vue.prototype.$textMsg = textMsg
Vue.prototype.$errMsg = errMsg
// 验证
import testVerify from '@/utils/index.js'
Vue.prototype.$testVerify = testVerify
// 时间
import times from '@/utils/times.js'
Vue.prototype.$times = times

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
