export default {
  // 手机号验证
  isvalidPhone(phone) {
    const reg = /^1(3|4|5|6|7|8|9)\d{9}$/
    return reg.test(phone)
  },
  // 富文本是否为空验证
  isContent(str) {
    let num = 0,
      reg = /<p>(&nbsp;|&nbsp;\s+)+<\/p>|<p>(<br>)+<\/p>/g;
    while (num < str.length && str != "") {
      num++;
      let k = str.match(reg);
      if (k) {
        str = str.replace(k[0], "");
      }
    }
    return str == "";
  }
}
