import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import {
  FrameMain
} from '../frame'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => {})
};

Vue.use(VueRouter)

const routes = [{
    path: '*',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'), //登录
  },
  {
    path: '/textPage/:type',
    name: 'textPage',
    component: () => import('@/views/other/textPage.vue'), //服务协议 隐私政策
  },
  {
    path: '/400',
    name: '400',
    component: () => import('@/views/errorPage/400.vue'), //400
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/errorPage/403.vue'), //403
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/errorPage/404.vue'), //404
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/errorPage/500.vue'), //500
  },
  {
    path: '/home',
    component: FrameMain,
    redirect: '/login',
    children: [{
      path: '/user/list',
      name: 'user_list',
      component: () => import('@/views/user/userList/index.vue') //用户列表
    }, {
      path: '/user/detail',
      name: 'user_detail',
      component: () => import('@/views/user/userDetail/detail.vue') //用户明细
    }, {
      path: '/proxy/list',
      name: 'proxy_list',
      component: () => import('@/views/user/proxyList/index.vue') //代理列表
    }, {
      path: '/proxy/detail',
      name: 'proxy_detail',
      component: () => import('@/views/user/proxyDetail/detail.vue') //代理明细
    }, {
      path: '/proxy/audit',
      name: 'proxy_audit',
      component: () => import('@/views/user/proxyAudit/index.vue') //代理审核
    }, {
      path: '/kol/list',
      name: 'kol_list',
      component: () => import('@/views/user/kolList/index.vue') //KOL列表
    }, {
      path: '/kol/audit',
      name: 'kol_audit',
      component: () => import('@/views/user/kolAudit/index.vue') //KOL审核
    }, {
      path: '/user/roles',
      name: 'user_roles',
      component: () => import('@/views/user/userRoles/index.vue') //角色管理
    }, {
      path: '/roles/edit',
      name: 'roles_edit',
      component: () => import('@/views/user/userRoles/edit.vue') //角色管理=编辑
    }, {
      path: '/recharge/config',
      name: 'recharge_config',
      component: () => import('@/views/setup/rechargeConfig/index.vue') //充值配置
    }, {
      path: '/system/config',
      name: 'system_config',
      component: () => import('@/views/setup/systemConfig/index.vue') //系统配置
    }, {
      path: '/agreement/document',
      name: 'agreement_document',
      component: () => import('@/views/setup/agreementDocument/index.vue') //协议文件
    }, {
      path: '/version/control',
      name: 'version_control',
      component: () => import('@/views/setup/version/index.vue') //版本设置
    }, {
      path: '/account/manage',
      name: 'account_manage',
      component: () => import('@/views/purview/accountManage/index.vue') //账号管理
    }, {
      path: '/role/manage',
      name: 'role_manage',
      component: () => import('@/views/purview/roleManage/index.vue') //角色管理
    }, {
      path: '/role/purview',
      name: 'role_purview',
      component: () => import('@/views/purview/roleManage/purview.vue') //配置权限
    }, {
      path: '/role/member',
      name: 'role_member',
      component: () => import('@/views/purview/roleManage/member.vue') //成员管理
    }, {
      path: '/operation/log',
      name: 'operation_log',
      component: () => import('@/views/purview/operationLog/index.vue') //操作日志
    }, {
      path: '/train/model',
      name: 'train_model',
      component: () => import('@/views/train/model/index.vue') //模型训练
    }, {
      path: '/train/sound',
      name: 'train_sound',
      component: () => import('@/views/train/sound/index.vue') //声音克隆
    }, {
      path: '/works/video',
      name: 'works_video',
      component: () => import('@/views/works/video/index.vue') //视频管理
    }, {
      path: '/works/audio',
      name: 'works_audio',
      component: () => import('@/views/works/audio/index.vue') //音频管理
    }, {
      path: '/works/draw',
      name: 'works_draw',
      component: () => import('@/views/works/draw/index.vue') //绘画管理
    }, {
      path: '/works/comic',
      name: 'works_comic',
      component: () => import('@/views/works/comic/index.vue') //漫画管理
    }, {
      path: '/activity/list',
      name: 'activity_list',
      component: () => import('@/views/activity/activityList/index.vue') //活动列表
    }, {
      path: '/activity/add',
      name: 'activity_add',
      component: () => import('@/views/activity/activityList/add.vue') //活动列表添加
    }, {
      path: '/course/catalog',
      name: 'course_catalog',
      component: () => import('@/views/activity/courseCatalog/index.vue') //课程目录
    }, {
      path: '/activity/course',
      name: 'activity_course',
      component: () => import('@/views/activity/activityCourse/index.vue') //活动课程
    }, {
      path: '/activity_course/add',
      name: 'activity_course_add',
      component: () => import('@/views/activity/components/activity_course_add/index.vue') //活动课程添加
    }, {
      path: '/advert/list',
      name: 'advert_list',
      component: () => import('@/views/activity/advertList/index.vue') //广告列表
    }, {
      path: '/enroll/list',
      name: 'enroll_list',
      component: () => import('@/views/activity/enrollList/index.vue') //报名列表
    }, {
      path: '/article/article',
      name: 'article_article',
      component: () => import('@/views/article/articleList/index.vue') //文章列表
    }, {
      path: '/article/add',
      name: 'article_add',
      component: () => import('@/views/article/articleList/add.vue') //文章添加
    }, {
      path: '/article/category',
      name: 'article_category',
      component: () => import('@/views/article/articleCategory/index.vue') //文章目录
    }, {
      path: '/article/label',
      name: 'article_label',
      component: () => import('@/views/article/labelManage/index.vue') //文章标签
    }, {
      path: '/article/agreement',
      name: 'article_agreement',
      component: () => import('@/views/article/agreement/index.vue') //协议文件
    }, {
      path: '/flow/list',
      name: 'flow_list',
      component: () => import('@/views/flow/flowList/index.vue') //流量管理-流量圈
    }, {
      path: '/flow/add',
      name: 'flow_add',
      component: () => import('@/views/flow/components/flowAdd/index.vue') //流量管理-流量圈-添加
    }, {
      path: '/flow/comment',
      name: 'flow_comment',
      component: () => import('@/views/flow/flowComment/index.vue') //流量管理-评论列表
    }, {
      path: '/flow/reply',
      name: 'flow_reply',
      component: () => import('@/views/flow/flowReply/index.vue') //流量管理-回复列表
    }, {
      path: '/member/group',
      name: 'member_group',
      component: () => import('@/views/member/memberGroup/index.vue') //会员库-会员组
    }, {
      path: '/member/inventory',
      name: 'member_inventory',
      component: () => import('@/views/member/memberInventory/index.vue') //会员库-会员清单
    }, {
      path: '/member/config',
      name: 'member_config',
      component: () => import('@/views/member/memberConfig/index.vue') //会员库-会员设置
    }, {
      path: '/member/list',
      name: 'member_list',
      component: () => import('@/views/member/memberList/index.vue') //会员库-会员列表
    }, {
      path: '/material/background',
      name: 'material_background',
      component: () => import('@/views/material/background/index.vue') //素材-背景
    }, {
      path: '/material/picture',
      name: 'material_picture',
      component: () => import('@/views/material/picture/index.vue') //素材-图片
    }, {
      path: '/material/music',
      name: 'material_music',
      component: () => import('@/views/material/music/index.vue') //素材-音乐
    }, {
      path: '/material/video',
      name: 'material_video',
      component: () => import('@/views/material/video/index.vue') //素材-视频
    }, {
      path: '/material/text',
      name: 'material_text',
      component: () => import('@/views/material/text/index.vue') //素材-文本
    }, {
      path: '/material/captions',
      name: 'material_captions',
      component: () => import('@/views/material/captions/index.vue') //素材-字幕
    }, {
      path: '/material/captions/add_edit',
      name: 'material_captions_add_edit',
      component: () => import('@/views/material/captions/add_edit.vue') //素材-字幕-编辑/添加
    }, {
      path: '/material/filter',
      name: 'material_filter',
      component: () => import('@/views/material/filter/index.vue') //素材-滤镜
    }, {
      path: '/material/clock',
      name: 'material_clock',
      component: () => import('@/views/material/clock/index.vue') //素材-实时挂件
    }, {
      path: '/material/screen',
      name: 'material_screen',
      component: () => import('@/views/material/screen/index.vue') //素材-公屏
    }, {
      path: '/material/classify',
      name: 'material_classify',
      component: () => import('@/views/material/classify/index.vue') //素材-分类管理
    }, {
      path: '/market/model',
      name: 'market_model',
      component: () => import('@/views/market/model_management/index.vue') //数字市场-模特管理
    }, {
      path: '/market/model/add_edit',
      name: 'market_model_add_edit',
      component: () => import('@/views/market/model_management/add_edit.vue') //数字市场-模特管理-编辑/添加
    }, {
      path: '/market/card',
      name: 'market_card',
      component: () => import('@/views/market/card/index.vue') //数字市场-数字名片
    }, {
      path: '/market/template',
      name: 'market_template',
      component: () => import('@/views/market/template/index.vue') //数字市场-模板市场
    }, {
      path: '/market/audio',
      name: 'market_audio',
      component: () => import('@/views/market/audio/index.vue') //数字市场-声音市场
    }, {
      path: '/market/audio/add_edit',
      name: 'market_audio_add_edit',
      component: () => import('@/views/market/audio/add_edit.vue') //数字市场-声音市场-编辑/添加
    }, {
      path: '/market/live_template',
      name: 'market_live_template',
      component: () => import('@/views/market/live_template/index.vue') //数字市场-直播模板
    }, {
      path: '/help/category',
      name: 'help_text',
      component: () => import('@/views/help/category/index.vue') //帮助中心-分类管理
    }, {
      path: '/help/document',
      name: 'help_document',
      component: () => import('@/views/help/document/index.vue') //帮助中心-文档列表
    }, {
      path: '/help/document/add_edit',
      name: 'help_document_add_edit',
      component: () => import('@/views/help/document/add_edit.vue') //帮助中心-文档列表-编辑/添加
    }, {
      path: '/help/theme',
      name: 'help_theme',
      component: () => import('@/views/help/theme/index.vue') //帮助中心-专题管理
    }, {
      path: '/help/theme/detail',
      name: 'help_theme_detail',
      component: () => import('@/views/help/theme/detail.vue') //帮助中心-专题管理-明细
    }, {
      path: '/help/feedback',
      name: 'help_feedback',
      component: () => import('@/views/help/feedback/index.vue') //帮助中心-问题反馈
    }]
  }
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (store.state.token) {
    next()
  } else {
    if (to.path == '/login') {
      next()
    } else {
      // 清除token
      store.commit('DEL_TOKEN', '')
      store.commit('DEL_ROLE', '')
      store.commit('userInfo/DEL_USER_INFO', '')
      next('/login')
    }
  }
})
export default router
